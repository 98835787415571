@import "stylesheets/colors";

.mba-heading {
  @apply flex items-center text-sapphire;

  &.heading--emoji,
  &.heading--icon,
  &.heading--image {
    @apply flex items-center;
  }

  .heading__emoji,
  .heading__image,
  .heading__icon {
    @apply flex-shrink-0;
  }

  .heading__title > u {
    text-decoration-color: colors("dynamic");
    text-decoration-skip-ink: none;
    text-underline-offset: -0.1rem;
  }

  /*** Sizes ***/
  &.heading--xxl {
    @apply leading-tight text-5xl font-bold lg:leading-tight lg:text-6xl;
    u {
      text-decoration-thickness: 16px;
    }
    .heading__emoji,
    .heading__image,
    .heading__icon {
      @apply w-14 h-14;
      &.left {
        @apply mr-2;
      }
      &.right {
        @apply ml-2;
      }
    }
  }

  &.heading--xl {
    @apply leading-normal text-4xl lg:text-5xl font-bold lg:leading-tight;
    u {
      text-decoration-thickness: 12px;
    }
    .heading__emoji,
    .heading__image,
    .heading__icon {
      @apply w-14 h-14;
      &.left {
        @apply mr-2;
      }
      &.right {
        @apply ml-2;
      }
    }
  }

  &.heading--lg {
    @apply leading-normal text-3xl font-semibold;
    u {
      text-decoration-thickness: 10px;
    }
    .heading__emoji,
    .heading__image,
    .heading__icon {
      @apply w-8 h-8;
      &.left {
        @apply mr-4;
      }
      &.right {
        @apply ml-4;
      }
    }
  }

  &.heading--md {
    @apply text-2xl font-semibold;
    u {
      text-decoration-thickness: 8px;
    }
  }

  &.heading--sm {
    @apply text-lg font-semibold;
    u {
      text-decoration-thickness: 6px;
    }
    .heading__emoji,
    .heading__image,
    .heading__icon {
      @apply w-5 h-5;
      &.left {
        @apply mr-2;
      }
      &.right {
        @apply ml-2;
      }
    }
  }

  &.heading--xs {
    @apply text-base font-semibold;

    u {
      text-decoration-thickness: 4px;
    }
    .heading__emoji,
    .heading__image,
    .heading__icon {
      @apply w-4 h-4;
      &.left {
        @apply mr-2;
      }
      &.right {
        @apply ml-2;
      }
    }
  }

  b {
    @apply text-primary;
  }

  /*** colors ***/
  @each $color-name, $color-value in $oqoro-colors {
    // Color
    &.heading--color-#{"" + $color-name} {
      color: $color-value;
    }

    // Accent color
    &.heading--accent-color-#{"" + $color-name} {
      em {
        color: $color-value;
        font-weight: inherit;
      }
    }
  }
}

// Underline offset > for Safari
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .mba-heading .heading__title > u {
      text-underline-offset: 0.3rem;
    }
  }
}

// Underline offset > for Firefox
@media screen and (min--moz-device-pixel-ratio: 0) {
  .mba-heading .heading__title > u {
    text-underline-offset: 0.4rem;
  }
}
