.oq-agenda {
  .day-slot {
    @apply flex flex-col bg-white p-2;
    &:not(.available) {
      background-image: linear-gradient(135deg, #ddd 10%, transparent 10%, transparent 50%, #ddd 50%, #ddd 60%, transparent 60%, transparent 100%);
      background-size: 7.07px 7.07px;
      .free-slot-container {
        @apply hidden;
      }
    }
    &:not(.current-month) {
      @apply opacity-60;
    }

    &.today > time {
      @apply flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white;
    }

    .free-slot {
      @apply text-xs font-light;
    }

    .events-container {
      @apply flex flex-col mt-2 gap-1;
    }

    .event {
      @apply flex flex-col rounded px-1 py-0.5 text-xs leading-4 font-medium;
      time {
        @apply font-light flex-none text-2xs;
      }
    }
  }
}