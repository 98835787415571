.page {
  @apply flex flex-col flex-grow overflow-y-auto;
  .page__header {
    @apply flex flex-col bg-white w-full px-8 pt-4 border-b border-slate-200;

    .header__topbar {
      @apply flex flex-wrap items-center mb-4 justify-between;
    }

    .header__actions {
      @apply flex flex-wrap items-center;
    }
  }
}