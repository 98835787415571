.oq-panel {
  @apply w-screen h-screen fixed z-10 inset-0;
  animation: 600ms backdrop-blur-fade-in forwards;
  &:not(.open) {
    @apply hidden;
  }

  &.close {
    display: block;
    animation: 600ms panel-close-slide forwards;
  }

  .overlay {
    @apply w-full h-full;
  }
  .content_container {
    @apply overflow-auto max-w-3xl right-0 absolute translate-x-full bg-white w-full h-full md:w-1/2;
    animation: 600ms panel-open-slide forwards;
  }

  .spinner {
    @apply m-auto;
  }

  .panel__footer {
    @apply bg-red-500;
  }
}

@keyframes backdrop-blur-fade-in {
  to {
    @apply backdrop-blur-sm backdrop-brightness-75;
  }
}

@keyframes panel-open-slide {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes panel-close-slide {
  to {
    transform: translateX(100%);
  }
}
