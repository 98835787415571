.oq-avatar {
  @apply flex;

  .avatar__container {
    @apply relative shrink-0 flex flex-col items-center justify-center bg-light rounded-full text-center w-10 h-10 text-sm font-semibold;
  }

  .avatar__label-container {
    @apply flex flex-col;
  }

  .avatar__icon {
    @apply rounded-full object-cover;
  }

  .avatar__sub-icon {
    @apply absolute bottom-0 right-0 rounded-full bg-gray-50;
    outline: solid 1.5px white;
    padding: 1px;
  }

  .avatar__label {
    @apply font-semibold text-gray-700;
  }

  .avatar__sub-label {
    @apply font-normal text-tertiary;
  }

  &.size--xs {
    .avatar__container {
      @apply w-6 h-6 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-2.5 h-2.5;
      }
      .avatar__icon {
        @apply h-6 w-6 #{!important};
      }
    }
  }

  &.size--sm {
    .avatar__container {
      @apply w-8 h-8 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-3 h-3;
      }
      .avatar__icon {
        @apply h-6 w-6 #{!important};
      }
    }
    .avatar__label-container {
      @apply pl-2.5;
      .avatar__label {
        @apply text-sm;
      }
      .avatar__sub-label {
        @apply text-xs;
      }
    }
  }

  &.size--md {
    .avatar__container {
      @apply w-10 h-10 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-3.5 h-3.5;
      }
      .avatar__icon {
        @apply h-8 w-8 #{!important};
      }
    }
    .avatar__label-container {
      @apply pl-3;
      .avatar__label {
        @apply text-sm;
      }
      .avatar__sub-label {
        @apply text-sm;
      }
    }
  }

  &.size--lg {
    .avatar__container {
      @apply w-12 h-12 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-4 h-4;
      }
      .avatar__icon {
        @apply h-10 w-10 #{!important};
      }
    }
    .avatar__label-container {
      @apply pl-3;
      .avatar__label {
        @apply text-md;
      }
      .avatar__sub-label {
        @apply text-md;
      }
    }
  }

  &.size--xl {
    .avatar__container {
      @apply w-14 h-14 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-4.5 h-4.5;
      }
      .avatar__icon {
        @apply h-12 w-12 #{!important};
      }
    }
    .avatar__label-container {
      @apply pl-4;
      .avatar__label {
        @apply text-lg;
      }
      .avatar__sub-label {
        @apply text-md;
      }
    }
  }

  &.size--2xl {
    .avatar__container {
      @apply w-16 h-16 p-1 text-xs;
      .avatar__sub-icon {
        @apply w-5 h-5;
      }
      .avatar__icon {
        @apply h-14 w-14 #{!important};
      }
    }
  }

  &.avatar--image {
    @apply p-0 border-none #{!important};
  }

}

.oq-avatar-group {
  @apply flex;
  .oq-avatar {
    &:not(:first-of-type) {
      &.size--sm {
        @apply -ml-4;
      }

      &.size--md {
        @apply -ml-6;
      }
    }

    &:hover {
      @apply z-10;
    }
  }
}
