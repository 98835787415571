.slider {
  .slider__tab {
    @apply cursor-pointer;
    &.active {
      @apply text-blue-main font-extrabold;
    }
  }
  .slider__panel:not(.active) {
    @apply hidden;
  }
}
