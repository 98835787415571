.navbar {
  // Ligne à remplacer quand bandeau Oqoro supprimé
  @apply flex flex-col w-full px-4 py-4 lg:py-4 justify-center;
  // @apply flex flex-col w-full z-20 fixed top-0 px-6 py-4 justify-center;

  // Bloc à décommenter quand bandeau Oqoro supprimé
  // &.scrolled-down {
  //   transform: translateY(-100%);
  //   transition: all 0.3s ease-in-out;
  // }
  // &.scrolled-up {
  //   transform: translateY(0);
  //   transition: all 0.3s ease-in-out;
  // }
  .navbar-content {
    @apply flex items-center justify-between w-full self-center lg:px-6;
  }
}
