.oq-check-boxes {
  @apply flex flex-col gap-1;
}

.oq-check-box {
  label {
    @apply flex items-start gap-2 text-md;
    input {
      @apply mt-0.5 h-5 w-5 rounded border-gray-300 text-brand-600 focus:ring-brand-600;
      &:checked {
        @apply border-brand-600;
      }
    }
    span {
      @apply inline opacity-60;
      text-wrap: wrap;
    }

    &:has(input:checked) {
      span {
        @apply opacity-100;
      }
    }
  }
}