.mba-avatar {
  @apply shrink-0 flex flex-col items-center justify-center bg-light p-2 rounded-full text-center w-10 h-10 text-sm font-semibold;

  &.avatar--image {
    @apply bg-white p-0;
  }

  &.size--sm {
    @apply w-6 h-6 p-1 text-xs;
  }
}