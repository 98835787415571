.stepper {
  // Défaut (vertical)
  .stepper__step {
    @apply flex items-center text-gray-500 font-bold py-2 leading-6 text-md relative;
    .step__done-icon {
      @apply w-4 h-4 absolute left-[-25px] top-[13px];
    }
    &.step--active {
      @apply text-blue-main;
    }
    &.step--done {
      .step__label {
        @apply font-medium text-blue-main;
      }
    }
  }

  // Variante horizontale
  &--horizontal {
    @apply hidden divide-y divide-primary-200 rounded-xl border border-primary-200 mx-4 lg:mx-0 lg:flex lg:divide-y-0 lg:mt-6;
    .stepper__step {
      @apply relative md:flex md:flex-1;
      .svg-separator {
        @apply absolute right-0 top-0 hidden h-full w-5 md:block text-[#bdacfa];
        & svg {
          @apply h-full w-full;
        }
      }
      &:last-child .svg-separator {
        @apply hidden;
      }
      .step__wrapper {
        @apply flex items-center px-2 md:px-6 font-medium text-gray-500;
        .step__circle {
          @apply flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-grey;
        }
        .step__text-wrapper {
          @apply flex flex-col mx-4 text-md;
          .step__label {
            @apply mr-4;
          }
        }
      }

      &.step--active {
        .step__circle {
          @apply border-purple-main text-oqoro-purple;
        }
        .step__label {
          @apply text-oqoro-purple;
        }
      }

      &.step--done {
        .step__circle {
          @apply border-indigo-600 bg-indigo-600;
          & svg {
            @apply h-6 w-6 text-white;
          }
        }
        .step__value {
          @apply font-extrabold text-oqoro-blue;
        }
      }
    }
  }
}
