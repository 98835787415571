@import "stylesheets/colors";

@mixin setAlertStyle($color) {
  .alert__icon {
    color: theme("colors.#{$color}.600");
  }
}

.oq-alert {
  @apply flex flex-col rounded-xl border-gray-300 leading-5 font-inter bg-white;

  &.bordered {
    @apply border;
  }

  @each $color in $colors {
    &.alert--color-#{$color} {
      @include setAlertStyle(#{$color});
    }
  }
  &.padded {
    .alert__content {
      @apply p-4;
    }
  }

  .alert__content {
    @apply flex;
    @apply text-gray-600 font-normal text-sm;

    .alert__icon {
      @apply h-10 w-10 mr-2 stroke-2 -mt-2 -ml-2;
    }

    .alert__title {
      @apply font-semibold text-gray-700;
    }
  }

  &.alert--type-full {
    @apply flex-row rounded-none border-0 border-b justify-center;
    .alert__content {
      @apply flex items-center w-full max-w-7xl grow px-8 py-3;
      .alert__message {
        @apply w-full flex-row items-center;
      }
    }
    .alert__icon {
      @apply h-10 w-10 mr-2 stroke-2 m-0 #{!important};
    }
  }

}
