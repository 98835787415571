@import "stylesheets/colors";

.mba-gallery {
  @apply flex flex-col w-full h-full text-white bg-gray-200;

  &--dark {
    background-color: black;
    color: white;
  }

  .gallery__topbar, .gallery__bottombar {
    @apply w-full flex items-center absolute py-8 md:py-4 px-8 bg-transparent h-20;
  }

  .gallery__closeBtn {
    @apply hidden md:flex justify-center items-center rounded-full cursor-pointer mr-2 p-4 border-grey-light border bg-white w-10 h-10 text-black;
  }

  .gallery__fileIndex {
    @apply flex flex-row justify-center absolute bg-white border border-grey-light text-oqoro-blue rounded-3xl px-4 py-2;

    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    left: calc(50% - 2rem);
  }

  .gallery__slideshow-container {
    @apply flex flex-grow justify-center items-center md:px-12 w-full h-full self-center;
  }

  .gallery__slideshow-content {
    @apply flex flex-row flex-grow md:items-center justify-center max-w-full md:max-w-8xl md:max-h-[80%] max-h-full h-full overflow-hidden rounded-4xl border-[10px] md:border-[3px] border-white;

    box-shadow: 0px 17px 80px rgba(199, 191, 222, 0.7);
    box-sizing: border-box;
  }

  .gallery__mobile-container {
    @apply flex md:hidden justify-center items-center space-x-8 pb-8;
    @supports (-webkit-touch-callout: none) {
      @apply pb-32;
    }
  }

  @mixin capsule {
    @apply border rounded-full p-2 border-white cursor-pointer;
    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .gallery__capsule {
    @include capsule();
    @apply flex flex-shrink-0 h-20 w-10 text-white -m-10 self-center;
    border: none;

    background: rgba(34, 26, 91, 0.3);
    backdrop-filter: blur(12px);
    &.gallery__capsule__right {
      border-radius: 18px 0px 0px 18px;
    }

    &.gallery__capsule__left {
      border-radius: 0px 18px 18px 0px;
    }
  }

  .gallery__capsule-mobile {
    @include capsule();
    @apply flex;
  }

  .gallery__arrows {
    @apply bg-transparent self-center flex grow;
  }
}
