@import "stylesheets/colors";

.card-shape {
  @apply flex relative bg-white rounded-2xl p-2 transition-all;
  // Workaround : empty 3D transform to trigger more material acceleration on iPhones so as to avoid flickering
  -webkit-transform: translate3d(0, 0, 0);

  &.orientation--vertical {
    @apply flex-col pb-4;
  }

  &.orientation--horizontal {
    @apply gap-2 md:gap-4;
  }

  &.card--clickable:hover {
    @apply shadow-purple;
  }

  .card__link {
    @apply absolute block inset-0 outline-none h-full w-full rounded-2xl;
    z-index: 1;
  }
}
