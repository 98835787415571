.number-input__wrapper {
  @apply flex items-center space-x-4;
  .number-input__value {
    @apply text-primary text-lg w-4 text-center #{!important};
  }

  label {
    @apply text-light text-sm font-normal mb-0;
    text-transform: none;
  }

  .btn {
    @apply h-6 w-6 border-2 #{!important};
    svg {
      @apply stroke-2 w-5 h-5;
    }
  }
  &.number-input__with-round-btn {
    .btn {
      @apply w-6 h-6 border border-grey rounded-full flex items-center justify-center
           hover:bg-purple-main hover:border-purple-main hover:opacity-60 #{!important};
      svg {
        @apply w-5 h-5 text-purple-main hover:text-white #{!important};
      }
    }
    label, :not(.checkbox):not(.radio) > label {
      text-transform: none;
      @apply text-indigo-200 text-base font-medium mb-0 #{!important};
    }
  }

  &.wrapper--inactive {
    .number-input__value {
      @apply text-light opacity-60 #{!important};
    }
    .btn {
      @apply text-light border-light opacity-50;
    }
  }
}