.oq-counter {
  @apply flex items-center space-x-2 w-min;
  .oq-form-group {
    @apply mb-0 #{!important};
  }
  .oq-input {
    @apply w-16 text-center;
    /*** Hide arrows from number input ***/
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
}