.mba-modal {
  &.modal--flash {
    &.panel__content {
      @apply bg-white;
    }
    .modal--flash__content {
      @apply font-semibold text-slate;
      a {
        @apply text-oqoro-purple;
      }
    }
  }
}
