.link {
  @apply flex items-center text-primary;

  .link__icon {
    @apply w-5 h-5;
  }

  /*** icon ***/
  &.link--align-icon-left {
    @apply flex-row-reverse;
    .link__icon {
      @apply mr-1;
    }
  }

  &.link--align-icon-right {
    .link__icon {
      @apply ml-1;
    }
  }
}