.multiselect {
  select {
    @apply hidden;
  }

  .multiselect__wrapper {
    @apply flex md:flex-wrap items-center flex-grow px-2 relative;
    .multiselect__option {
      @apply flex items-center sm:whitespace-nowrap bg-purple-main border border-grey text-white text-sm px-2 py-1 rounded-lg mx-0.5 my-0.5 break-all sm:break-normal;
      &__counter {
        @extend .multiselect__option;
        @apply bg-purple-300 border-none font-extrabold text-purple-main whitespace-nowrap;
      }
      .option__delete-btn {
        @apply cursor-pointer pl-1;
      }
    }

    input {
      width: auto;
      min-width: 100px;
      flex-grow: 1 !important;
      border: none;
      background: transparent;
      padding: 0.25rem !important;

      &:focus {
        @apply ring-0;
        border: none;
      }
      &:focus-visible {
        @apply ring-0;
        border: none;
        outline: none;
      }
    }
  }

  &.multiselect--style-outline {
    .multiselect__wrapper {
      .multiselect__option {
        @apply bg-white border-grey-light text-blue-main;
        &__counter {
          @apply bg-purple-300 border-none font-extrabold text-purple-main absolute right-0;
        }
      }
    }
  }

  .input_wrapper {
    @apply flex overflow-x-scroll w-full;
    .multiselect__option {
      @apply flex w-fit items-center whitespace-nowrap bg-purple-main border border-grey text-white text-sm px-2 py-1 rounded-lg mx-0.5 my-0.5;
      &__counter {
        @extend .multiselect__option;
        @apply bg-purple-300 border-none font-extrabold text-purple-main;
      }

      .option__delete-btn {
        @apply cursor-pointer pl-1;
      }
    }
  }

  .selection_wrapper {
    @apply flex flex-col space-y-2 my-4 overflow-y-scroll;
    .multiselect__option-mobile {
      @apply flex items-center justify-between px-2 py-3 border border-grey text-oqoro-blue font-medium text-base rounded-xl;

      .option__delete-btn {
        @apply cursor-pointer;
      }
    }
  }

  .multiselect__list {
    @apply mt-auto md:mt-0 md:divide-y text-md;
    .list__item {
      @apply flex items-center py-2 px-4 cursor-pointer text-grey transition-colors hover:bg-purple-300;
      .list__item__check {
        @apply h-4 w-4 mr-2 opacity-0;
      }

      &.list__item--checked {
        @apply text-md font-bold text-oqoro-purple;
        .list__item__check {
          @apply opacity-100 text-oqoro-purple;
        }
      }
    }
  }
}
