.dropzone {
  .dropzone__zone {
    @apply flex flex-col border-2 border-dashed rounded-2xl items-center justify-center p-8 text-grey text-sm;
    &.dragover {
      @apply border-light bg-purple-100;
    }

    svg.icon path {
      stroke-width: 1.5px;
    }

    input {
      @apply hidden;
    }
  }

  .files-list {
    @apply flex flex-col mt-2 divide-y space-y-1;
    .list__item {
      @apply flex flex-col bg-purple-100 rounded-lg relative;

      .list__item__content {
        @apply relative flex items-center w-full text-light text-sm p-2 ;
      }

      .error-icon {
        @apply hidden;
      }

      &.error {
        @apply bg-salmon-100;

        .list__item__content {
          @apply text-danger;
        }

        .file-icon {
          display: none;
        }

        .error-icon {
          display: block;
        }

        span.error {
          @apply text-danger text-xs;
        }
      }

      .progress-bar {
        @apply absolute top-0 bottom-0 bg-blue-100 rounded-lg;
        -webkit-transition: width 1s ease-in-out;
        -moz-transition: width 1s ease-in-out;
        -o-transition: width 1s ease-in-out;
        transition: width 1s ease-in-out;
      }

      .file-icon {
        @apply hidden;
      }


      &.upload-progress {
        .progress-bar {
        }
        .loader-icon {
        }
      }

      .delete-btn {
        display: none;
        @apply hover:text-danger;
      }

      &.deleting {
        @apply bg-salmon-100;
        .list__item__content {
          @apply text-danger;
        }
        .loader-icon {
          display: block;
        }
        .file-icon {
          display: none;
        }
      }

      &:not(.error) {
        :not([data-signed-id]), [data-signed-id=""] {
          .loader-icon {
            display: block;
          }
          .progress-bar {
            display: block;
          }
        }
      }


      &:not(.upload-progress) {
        &[data-signed-id]:not([data-signed-id=""]), &.error {
          &:not(.deleting) .loader-icon {
            @apply hidden;
          }

          .progress-bar {
            @apply hidden;
          }
        }
      }

      &[data-signed-id]:not([data-signed-id=""]):not(.error):not(.deleting):not(.upload-progress) {
        .list__item__content {
          @apply text-primary;
        }
        .delete-btn {
          display: block;
        }
        .file-icon {
          display: block;
        }
      }
    }
  }
}
