@import 'stylesheets/colors';

.frame {
  @apply relative flex flex-col border border-slate-200;

  &.frame--style-default {
    @apply bg-white rounded-lg;
  }

  &.frame--style-transparent {
    @apply border-0;
  }

  &.frame--style-primary {
    @apply bg-primary text-white border-0;
  }

  &.frame--style-light {
    @apply border-0;
    background: #ebf1f1;
  }

  &.frame--style-extralight {
    @apply border-0;
    background: #f2f8fe;
  }

  .frame__header {
    @apply flex flex-col border-b p-4 sm:px-8;
    @apply sm:flex-row;
    .header__picto {
      @apply mr-4 h-10 w-10;
    }

    .header__title {
      @apply flex flex-col flex-grow;
    }

    .frame__actions {
      @apply hidden my-0 mx-0;
      @apply sm:flex;
      .btn {
        @apply w-auto;
      }
    }
  }

  .frame__content {
    @apply flex flex-col p-4 sm:px-8;
  }

  .frame__actions {
    @apply flex items-center justify-center space-x-4 my-4 mx-4;
    @apply sm:hidden;
    .btn {
      @apply w-full;
    }
  }

  .frame__full-bg {
    @apply absolute w-screen left-0 top-0;
    z-index: -1;
  }
}
