.oq-admin-sidebar {
  @apply lg:w-18.5 lg:fixed lg:z-2;

  transition: width 200ms;
  filter: drop-shadow(0px 1px 2px #1018280F);
  filter: drop-shadow(0px 1px 3px #1018281A);

  .sidebar__container {
    @apply flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4;
  }

  .menu__label, .menu__short-label {
    @apply text-xs font-semibold leading-6 text-gray-400;
  }

  .menu__label {
    @apply lg:hidden overflow-hidden text-ellipsis;
    text-wrap: nowrap;
  }

  .menu__short-label {
    @apply text-center;
  }

  .menu__label__container {
    @apply h-6 border-t;
  }

  .menu__item {
    @apply flex flex-col;
    .item__label {
      @apply lg:hidden overflow-hidden text-ellipsis;
      text-wrap: nowrap;
    }
  }

  &.expanded, &:hover {
    @apply lg:w-72;
    .menu__label__container {
      @apply border-transparent;
    }
    .menu__label {
      @apply lg:block;
    }
    .menu__short-label {
      @apply lg:hidden;
    }
    .menu__item {
      .item__label {
        @apply lg:block;
      }
    }
  }

  .menu__item {
    a {
      @apply text-gray-700 hover:text-brand-600 hover:bg-gray-50 gap-x-3 flex rounded-md p-2 text-sm leading-6 font-semibold;
      svg {
        @apply text-gray-400;
      }

      &:hover {
        svg {
          @apply text-brand-600;
        }
      }
    }

    &.active {
      a {
        @apply bg-gray-50 text-brand-600;
        svg {
          @apply text-brand-600;
        }
      }
    }
  }
}
