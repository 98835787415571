.oq-fields-for {
  @apply flex flex-col w-full;
  .nested-container {
    @apply flex flex-col divide-y gap-6;
    .nested-form-container {
      @apply flex items-center gap-2;
      &:not(:first-of-type) {
        @apply pt-6;
      }
      .nested-form {
        @apply flex;
      }
      .oq-form-group.nested {
        @apply px-4;
      }
    }
  }
}
