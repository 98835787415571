.oq-modal {
  @apply relative z-10;
  &:not(.open) {
    @apply hidden;
  }

  .modal__container {
    @apply flex min-h-full items-end justify-center p-6 text-center sm:items-start sm:p-0 overflow-auto;
  }

  .modal__content {
    @apply py-4 transform rounded-xl bg-white text-left shadow-xl transition-all sm:w-full;
    &.padded {
      @apply px-4;
    }
  }

  &.size--full {
    .modal__content {
      @apply w-screen h-screen flex flex-col grow;
    }
  }

  &.size--md {
    .modal__content {
      @apply sm:max-w-lg;
    }
  }

  &.size--lg {
    .modal__content {
      @apply sm:max-w-3xl;
    }
  }

  &:not(.size--full) {
    .modal__container {
      @apply sm:pt-12;
      .modal__content {
        @apply sm:my-8;
      }
    }
  }

  .modal__header {
    @apply flex space-x-4 mb-6 -my-4;
    .header__icon {
      @apply h-12 w-12 p-2 shrink-0 stroke-2;
    }

    .header__title {
      @apply text-lg font-bold;
    }

    .header__subtitle {
      @apply text-sm text-gray-400;
    }
  }
  .modal__footer {
    @apply flex items-center justify-end space-x-2 mt-4;
  }
  .close-button {
    @apply absolute top-0 right-0;
  }

}
