@import "stylesheets/colors";

.card {
  @apply relative flex flex-col border bg-white border-slate-200 rounded-md flex-shrink-0;
  &.card--orientation-horizontal {
    @apply flex-row;
    .card__image {
      @apply rounded-l-md;
      img {
        @apply rounded-l-md;
      }
    }

    &.card--responsive {
      @apply flex-col w-auto h-auto shadow-lg sm:shadow-none sm:h-48 sm:w-full sm:flex-row;
      .card__image {
        @apply w-full h-36 sm:w-64 sm:h-full;
      }
    }
  }

  &.card--orientation-vertical {
    .card__image {
      @apply rounded-t-md h-36;
      img {
        @apply rounded-t-md;
      }
    }
  }

  &.card--color-light {
    @apply bg-background_lilas;
  }
  
  &.card--color-oqoro {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), $color-purple;
    @apply border-purple-main;
  }

  &.card--linkable {
    @apply cursor-pointer transition-all;
    &:hover {
      @apply shadow-lg;
      .card__image {
        img {
          @apply scale-110;
        }
      }
    }
  }

  &.card--borderless {
    @apply border-0;
  }

  &.card--paddingless {
    .card__content {
      @apply p-0
    }
  }

  &.card--active {
    @apply ring-4 ring-primary-500 ring-opacity-50;
  }

  .card__image {
    @apply bg-gray-100 flex-shrink-0 overflow-hidden;
    img {
      @apply object-cover w-full h-full transition-all duration-300 ease-in-out max-h-full max-w-full;
    }
  }

  .card__content {
    @apply flex flex-col flex-grow p-4;
  }

  .card__footer {
    @apply flex flex-col items-center justify-center rounded-b-md p-4;
  }
  &.card-oqoro {
    @apply rounded-1.5xl;
    .card__content {
      @apply p-6;
    }
  }
}
