.oq-input {
  @apply max-w-lg;

  &.with-suffix {
    @apply pr-6;
  }

  &[type="password"] {
    @apply text-2xl;
  }
}

.input-suffix {
  @apply text-base absolute right-0 inset-y-0 text-light flex items-center px-2;
}
