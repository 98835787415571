$lg-path-fonts: "~lightgallery/fonts/";
$lg-path-images: "~lightgallery/images/";
@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-rotate';
@import 'lightgallery/scss/lg-zoom';
@import 'lightgallery/scss/lg-fullscreen';

.oq-lightbox {
}

.lg-backdrop {
}

.lg-container {
  .lg-outer {
    .lg-has-iframe {
      @apply absolute inset-0;
    }
    iframe.lg-object {
      @apply h-full w-full;
    }
  }
}
