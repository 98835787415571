@import "stylesheets/colors";

.mba-pill {
  @apply flex items-center font-medium bg-black/[.1];

  .pill__icon {
    @apply w-4 h-4 mr-1;
  }

  &.pill--size-sm {
    @apply text-xs;
    @apply px-2 py-1;
    line-height: 18px;
  }

  &.pill--size-xs {
    @apply text-xs;
    @apply px-1 py-0.5;
  }

  /*** colors ***/
  @each $color-name, $color-value in $oqoro-colors {
    &.pill--color-#{"" + $color-name} {
      color: $color-value;
      background-color: #{"" + $color-value + "20"};
    }
  }
}
