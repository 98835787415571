@import 'mapbox-gl/dist/mapbox-gl.css';

.map-autocomplete {
  .map__autocomplete-input {
  }
  .map__autocomplete-list {
    li {
      @apply px-2.5 py-1.5 text-sm cursor-pointer hover:bg-extra-light;
    }
  }
  .map__map {
    @apply border rounded h-72;
  }
}