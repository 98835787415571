@import "stylesheets/colors";

.oqoro-input-field {
  @apply relative mb-6 w-full mt-0;
  label {
    @apply text-base font-medium text-blue-main mb-0;
  }
  select {
    @apply w-full;
  }
  input:not([type=submit]):not([type=file]):not([type=radio]):not([type=checkbox]), textarea, select {
    box-shadow: 0px 4px 70px rgba(127, 135, 158, 0.05);
    @apply rounded-1.5xl border py-3 text-base bg-white transition-all duration-100;
  }
  input:not([type=submit]):not([type=file]):hover:not([type=checkbox]), textarea:hover, select:hover {
    @apply border-gray-700;
  }
  input:not([type=submit]):not([type=file]):not([type=radio]):not([type=checkbox]):focus, textarea:focus, select:focus {
    box-shadow: none;
    @apply border-purple-main;
  }
  input:not([type=submit]):not([type=file]):not([type=checkbox])::placeholder, textarea::placeholder, select::placeholder {
    @apply not-italic
  }
  input[type=checkbox]:not([type=submit]):not([type=file]) {
    @apply rounded-md;
    &:checked {
      outline: none;
      @apply bg-purple-main/60 border border-purple-main;
    }
    &:focus {
      outline: none;
    }
  }
  .field.boolean label {
    @apply text-base text-primary #{!important};
  }
  .oqoro-input-field__icon {
    @apply w-5 h-5 absolute top-[51px] left-[20px] text-oqoro-purple;
  }
  &.oqoro-input-field__no-label .oqoro-input-field__icon {
    @apply top-[18px];
  }
  &.oqoro-input-field__with-icon {
    input:not([type=submit]):not([type=file]):not([type=checkbox]):not([type=radio]),
    textarea:not([type=checkbox]):not([type=radio]), select {
      @apply pl-11;
    }
  }
  &.oqoro-input-field__inline {
    .control, .check_boxes {
      @apply flex flex-row justify-start items-center;
      .radio:not(:last-child) {
        @apply mr-7;
      }
      div.radio .input.radio_buttons {
        @apply mr-2 #{!important};
      }
    }
  }
  .field {
    @apply px-0;
    &:not(.select-input__wrapper):not(.tel).boolean {
      overflow: unset;
    }
    &.radio_buttons div.radio {
      label {
        @apply text-blue-main text-base font-medium cursor-pointer;
      }
      input[type="radio"] {
        @apply absolute opacity-0;
        + label:before {
          @apply content-[''] align-top relative bg-white border-grey-light border-[1.5px] w-5 h-5 rounded-full cursor-pointer text-center mr-2 transition-all duration-200 ease-in;
        }
        &:checked + label:before {
          box-shadow: inset 0 0 0 4px colors('background_lilas');
          @apply bg-purple-main/60;
        }
        &:focus + label:before {
          outline: none;
          @apply bg-purple-main/60;
        }
        + label:empty:before {
          margin-right: 0;
        }
      }
    }
  }
}
