@import "stylesheets/colors";

.button {
  @apply flex items-center w-max text-center font-medium rounded-full text-white;
  letter-spacing: 0.01em;

  .button__label {
    @apply whitespace-nowrap;
  }

  &.button--submit,
  &.button--submit.button--color-primary.button--style-solid {
    @apply button-style-primary transition-all;
    &:hover {
      @apply bg-blue-main border-black;
      background-image: none;
    }
  }

  &.button--style-outline {
    @apply border;
  }

  .button__label {
    @apply whitespace-nowrap;
  }

  .button__icon {
    @apply flex-shrink-0;
  }

  /*** sizes ***/
  &.button--size-sm {
    @apply px-2 py-1 text-xs;
    line-height: 18px;
    .button__icon {
      @apply w-4.5 h-4.5;
    }
  }

  &.button--size-md {
    @apply px-6 py-3 text-md font-extrabold;
    &.button--icon-only {
      @apply px-2;
    }
    .button__icon {
      @apply w-5 h-5;
    }
  }

  &.button--size-lg {
    @apply px-5 py-2 text-base;
    &.button--icon-only {
      @apply px-2;
    }
  }
  &.button--size-xl {
    @apply px-8 py-3 text-lg;
    &.button--icon-only {
      @apply px-3;
    }
  }

  /*** icon ***/
  &.button--align-icon-left {
    @apply flex-row-reverse;
    &:not(.button--icon-only) .button__icon {
      @apply mr-2;
    }
  }

  &.button--align-icon-right {
    &:not(.button--icon-only) .button__icon {
      @apply ml-2;
    }
  }

  /*** toggle  ***/
  &.button--toggle {
    &.button--toggle-style-outline {
      @apply bg-transparent text-purple-main border-grey-light;
    }
    &.button--toggle-style-outline-active span,
    &.button--style-outline-active span {
      &::before {
        @apply content-[''] inline-block rotate-45 h-4 w-2.5 border-b-2 border-r-2 mr-2;
      }
    }
    &.button--toggle-style-outline-active {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), $color-purple;
      @apply text-purple-main border-purple-main;
      span::before {
        @apply border-purple-main;
      }
    }
  }

  /*** colors ***/
  @each $color-name, $color-value in $oqoro-colors {
    &.button--color-#{"" + $color-name} {
      /*** styles ***/
      &.button--style-solid {
        border-color: $color-value;
        background: $color-value;
      }
      &.button--style-outline {
        border-color: $color-value;
        background: transparent;
        color: $color-value;
        transition: all 0.2s;
      }
      &.button--style-outline:hover {
        background: $color-value;
        color: white;
      }
      &.button--style-discreet {
        @apply border border-grey;
        background: transparent;
        color: $color-value;
      }
      &.button--style-transparent {
        @apply border border-transparent;
        background: transparent;
        color: $color-value;
      }

      /*** variants ***/
      &.button--shadowed {
        //box-shadow: 4px 4px 0px darken($color-value, 25%);
      }
    }
  }

  &.button--icon-only {
    @apply rounded-full justify-center;
    .button__icon {
      @apply ml-0 mr-0 #{!important};
    }
    .button__label {
      @apply hidden;
    }
  }

  /*** Disabled ***/
  &:disabled {
    @apply opacity-50;
  }
}
