.OQORO-tab__wrapper {
  @apply flex flex-row items-center overflow-x-auto px-8 md:px-0;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .OQORO-tab__inner-wrapper {
    @apply flex flex-row grow shrink-0 items-center bg-purple-200 rounded-xl snap-x;
    .OQORO-tab__tab {
      @apply snap-center flex-shrink-0 flex-grow cursor-pointer text-center font-medium rounded-xl m-1 p-2 text-slate min-w-min;
      &.active {
        @apply text-purple-main bg-white;
      }
    }
  }

  &.size--sm {
    .OQORO-tab__inner-wrapper {
      .OQORO-tab__tab {
        @apply p-1 text-sm;
        &.active {
        }
      }
    }
  }
}

.OQORO-tab__panel {
  @apply flex flex-col;
  &:not(.open) {
    display: none;
  }
}
