.oq-slide-over {
  @apply relative z-10;

  .backdrop {
    @apply fixed inset-0 bg-gray-950 transition ease-in-out duration-500 bg-opacity-70;
  }

  .panel {
    @apply pointer-events-auto w-screen max-w-2xl transform transition ease-in-out duration-500 mb-0;
  }

  &.open {
    .panel {
      @apply translate-x-0;
    }
  }
  &:not(.open) {
    .backdrop {
      @apply opacity-0;
    }
    .panel {
      @apply translate-x-full;
    }
  }

  .panel__header {
    @apply flex p-6 items-center justify-between border-b border-gray-300 space-x-2.5 bg-gray-50;
  }

  .panel__content {
    @apply relative p-6 flex-1;
  }
}
