@import "stylesheets/colors";

$appearances: ("solid", "outline", "link", "ghost");
$variants: ("primary", "secondary", "tertiary");

@mixin setButtonStyle($appearance, $variant, $color, $inverted) {
  @if $appearance == "solid" {
    border-color: transparent;
    @if $variant == "primary" {
      @apply text-white;
      background-color: theme("colors.#{$color}.600");
      &:hover:not([disabled]) {
        background-color: theme("colors.#{$color}.700");
      }
      &:disabled {
        //@apply opacity-50;
        color: theme("colors.gray.700");
        background-color: theme("colors.gray.200");
        border-color: theme("colors.gray.300");
        &:hover {
          background-color: theme("colors.gray.200");
        }
      }
    }
    @if $variant == "secondary" {
      @apply border;
      background-color: theme("colors.#{$color}.50");
      border-color: theme("colors.#{$color}.300");
      color: theme("colors.#{$color}.700");
      &:hover:not([disabled]) {
        background-color: theme("colors.#{$color}.100");
      }
    }
    @if $variant == "tertiary" {
      @apply border;
      color: theme("colors.#{$color}.700");
      &:hover:not([disabled]) {
        background-color: theme("colors.#{$color}.50");
      }
    }
  }
  @if $appearance == "outline" {
    @apply border-1;
    @if $variant == "primary" {
      border-color: theme("colors.#{$color}.500");
      color: theme("colors.#{$color}.500");
      &:hover:not([disabled]) {
        background-color: theme("colors.#{$color}.500");
        @apply text-white;
      }
    }
    @if $variant == "secondary" {
      border-color: theme("colors.gray.200");
      color: theme("colors.#{$color}.500");
      &:hover:not([disabled]) {
        background-color: theme("colors.gray.200");
      }
    }
  }
  @if $appearance == "link" {
    @apply p-0;
    &:hover:not([disabled]) {
      .btn__label {
        @apply underline;
      }
    }
    @if $variant == "primary" {
      color: theme("colors.#{$color}.600");
      &:hover:not([disabled]) {
        color: theme("colors.#{$color}.700");
      }
    }
    @if $variant == "secondary" {
      color: theme("colors.#{$color}.300");
      &:hover:not([disabled]) {
        color: theme("colors.#{$color}.400");
      }
    }
  }
  @if $appearance == "ghost" {
    @apply bg-transparent border-transparent opacity-75;
    &:disabled {
      @apply opacity-50;
    }
    &:hover:not([disabled]) {
      @apply opacity-100;
    }
    @if $variant == "primary" {
      color: theme("colors.#{$color}.700");
    }
    @if $variant == "secondary" {
      color: theme("colors.#{$color}.400");
    }
  }
  @if $inverted {
    @apply text-white;
  }
}

.oq-btn {
  @apply relative inline-flex items-center rounded-md border border-transparent transition-colors select-none;
  @apply no-underline #{!important};
  filter: drop-shadow(0px 1px 2px #1018280D);

  &.btn--size-xs {
    @apply py-1 px-2 h-6;
    .btn__label {
      @apply text-xs;
    }

    .btn__icon {
      @apply h-4 w-4 stroke-2;
      svg {
        @apply max-h-4 max-w-4 h-full w-full;
      }
    }

    &.btn--icon-only {
      @apply px-1 py-1;
    }
  }

  &.btn--size-sm {
    @apply py-2 px-3 h-9;
    .btn__label {
      @apply text-sm;
    }

    .btn__icon {
      @apply h-5 w-5 stroke-2;
      svg {
        @apply max-h-5 max-w-5 h-full w-full;
      }
    }

    &.btn--icon-only {
      @apply px-2 py-2;
    }
  }

  &.btn--size-md {
    @apply px-3.5 py-2.5 h-10;
    .btn__label {
      @apply text-md;
    }

    .btn__icon {
      @apply h-5 w-5 stroke-2;
    }

    &.btn--icon-only {
      @apply px-2.5 py-2.5;
    }
  }

  &.btn--size-lg {
    @apply px-4 py-2.5 h-11;
    .btn__label {
      @apply text-lg;
    }
    .btn__icon {
      @apply h-5 w-5 stroke-2;
    }
    &.btn--icon-only {
      @apply px-2.5 py-2.5;
    }
  }

  &.btn--size-xl {
    @apply px-4.5 py-3 h-12;
    .btn__label {
      @apply text-xl;
    }
    .btn__icon {
      @apply h-5 w-5 stroke-2;
    }
    &.btn--icon-only {
      @apply px-3 py-3;
    }
  }

  &.btn--size-2xl {
    @apply px-5.5 py-4 h-15;
    .btn__label {
      @apply text-2xl;
    }
    .btn__icon {
      @apply h-6 w-6 stroke-2;
    }
    &.btn--icon-only {
      @apply px-4 py-4;
    }
  }

  &[disabled] {
    @apply opacity-50 cursor-default;
    &:active {
      pointer-events: none;
    }
  }

  &.btn--rounded {
    @apply rounded-full;
  }

  @each $appearance in $appearances {
    &.btn--appearance-#{$appearance} {
      @each $color in $colors {
        &.btn--color-#{$color} {
          @each $variant in $variants {
            &.btn--variant-#{$variant} {
              &.btn--inverted {
                @include setButtonStyle(#{$appearance}, #{$variant}, #{$color}, true);
              }

              &:not(.btn--inverted) {
                @include setButtonStyle(#{$appearance}, #{$variant}, #{$color}, false);
              }
            }
          }
        }
      }
    }
  }

  .btn__label {
    @apply font-semibold whitespace-nowrap;
  }

  &.btn--icon--left {
    &.btn--size-sm {
      .btn__icon {
        @apply mr-1;
      }
    }
    &.btn--size-md {
      .btn__icon {
        @apply mr-1;
      }
    }
    &.btn--size-lg {
      .btn__icon {
        @apply mr-1.5;
      }
    }
    &.btn--size-xl {
      .btn__icon {
        @apply mr-1.5;
      }
    }
    &.btn--size-2xl {
      .btn__icon {
        @apply mr-2.5;
      }
    }
  }

  &.btn--icon--right {
    &.btn--size-sm {
      .btn__icon {
        @apply ml-1;
      }
    }
    &.btn--size-md {
      .btn__icon {
        @apply ml-1;
      }
    }
    &.btn--size-lg {
      .btn__icon {
        @apply ml-1.5;
      }
    }
    &.btn--size-xl {
      .btn__icon {
        @apply ml-1.5;
      }
    }
    &.btn--size-2xl {
      .btn__icon {
        @apply ml-2.5;
      }
    }
  }

  .btn__icon {
    &.icon--trailing {
      @apply mr-0 ml-1;
    }
  }

  .badge {
    @apply absolute -top-1 -right-1;
  }
}
