@mixin setLabelStyle($appearance, $variant, $color) {
  @if $appearance == "solid" {
    border-color: transparent;
    @if $variant == "primary" {
      background-color: theme("colors.#{$color}.500");
      @apply text-white;
    }
    @if $variant == "secondary" {
      background-color: theme("colors.#{$color}.100");
      color: theme("colors.#{$color}.700");
    }
  }
  @if $appearance == "outline" {
    @apply border-1;
    @if $variant == "primary" {
      border-color: theme("colors.#{$color}.500");
      color: theme("colors.#{$color}.500");
    }
    @if $variant == "secondary" {
      border-color: theme("colors.gray.200");
      color: theme("colors.#{$color}.500");
    }
  }
}

$colors: ("primary", "success", "warning", "danger", "gray");
$appearances: ("solid", "outline");
$variants: ("primary", "secondary");

.oq-label {
  @apply inline-flex items-center p-1 rounded-md border-transparent;
  @apply no-underline #{!important};

  &.label--rounded {
    @apply rounded-full;
  }

  &.label--size-xs {
    @apply p-0.5;
    .label__label {
      @apply text-xs;
    }
    .label__icon {
      @apply h-3 w-3 stroke-2;
    }
  }

  @each $appearance in $appearances {
    &.label--appearance-#{$appearance} {
      @each $color in $colors {
        &.label--color-#{$color} {
          @each $variant in $variants {
            &.label--variant-#{$variant} {
              @include setLabelStyle(#{$appearance}, #{$variant}, #{$color});
            }
          }
        }
      }
    }
  }

  .label__label {
    @apply font-medium text-sm whitespace-nowrap;
  }

  &.label--icon-only {
    //@apply px-3 py-3;
    .label__icon {
      @apply h-4 w-4;
    }
  }

  .label__icon {
    @apply h-4 w-4 stroke-2;
  }

  &:not(.label--icon-only) {
    .label__icon {
      &.label__icon--left {
        @apply mr-2;
      }
    }
  }
}


.oq-label-group {
  @apply flex items-center;
  .oq-label {
    @apply rounded-none;
  }
  .oq-label:first-of-type {
    &:not(.label--rounded) {
      @apply rounded-l-md;
    }
    &.label--rounded {
      @apply rounded-l-full;
    }
  }
  .oq-label:last-of-type {
    &:not(.label--rounded) {
      @apply rounded-r-md;
    }
    &.label--rounded {
      @apply rounded-r-full;
    }
  }
}