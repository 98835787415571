@import "stylesheets/colors";

.button.button--cta,
.button.button--cta.button--color-primary.button--style-solid {
  @apply button-style-primary transition-all;
  &:hover {
    @apply bg-blue-main border-black;
    background-image: none;
  }
  /*** Disabled ***/
  &.button--cta-disabled {
    @apply bg-oqoro-gradient opacity-50 pointer-events-none;
  }
}
