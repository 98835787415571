@import 'stylesheets/colors';

.oqoro-badge {
  @apply flex items-center rounded-lg px-1.5 py-1.5 flex-shrink-0 leading-normal font-semibold;
  width: fit-content;

  .badge__icon {
    @apply rounded-full p-1;
  }

  &.badge--size-sm {
    @apply text-sm px-2 py-1 font-medium;
    .badge__icon {
      @apply mr-1.5;
      svg {
        @apply h-4 w-4 stroke-2;
      }
    }
  }

  &.badge--size-xs {
    @apply text-xs px-1.5 py-1;
    .badge__icon {
      @apply mr-1.5;
      svg {
        @apply h-3 w-3 stroke-2;
      }
    }
  }

  &.badge--size-xxs {
    @apply text-2xs px-1.5 py-0.5;
    .badge__icon {
      @apply mr-1;
      svg {
        @apply h-3 w-3 stroke-2;
      }
    }
  }

  &.badge--with-backdrop {
    background: rgba(63, 70, 124, 0.79);
  }
  @supports not( -moz-appearance:none ){
    &.badge--with-backdrop {
      background: rgba(63, 70, 124, 0.69);
      backdrop-filter: blur(8px);
    }
  }

}
