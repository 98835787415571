.burger {
  .burger-menu {
    @apply flex flex-col bg-white fixed inset-0 w-full h-full transition-transform transform -translate-x-full z-30;
    &.open {
      @apply translate-x-0;
    }
    .menu-image {
      @apply absolute top-0;
    }
    .burger-button {
      @apply ml-4 mt-16 bg-white p-4 rounded-full w-12 h-12 text-blue-main shrink-0;
      z-index: 1;
    }
    .home-button-logo {
      @apply flex justify-center;
    }
    .menu-content {
      @apply flex flex-col flex-grow pt-40 overflow-x-auto;
    }
    .menu-content li {
      @media (min-width: 1024px) {
        @apply hover:underline;
      }
      @apply active:underline;
    }
  }
}
