.oq-file-input {
  @apply flex flex-col space-y-2;

  input {
    @apply hidden;
  }

  .file-list {
    @apply flex flex-col space-y-1;
  }

  .dropzone {
    @apply flex flex-col bg-white items-center border-2 rounded-xl p-3 text-tertiary mb-3 space-y-2;
    &.dragover, &:hover {
      @apply cursor-pointer border-brand bg-brand-50;
    }
  }
}

.oq-file-item {
  @apply flex border rounded-lg p-2 bg-white;
  .filename {
    @apply font-semibold;
  }

  .filesize {
    @apply font-light text-xs;
  }

  .status-text {
    @apply text-xs text-tertiary;
  }

  .progress-bar {
    @apply hidden;
    .progress {
      transition: width 100ms;
    }
  }

  &.uploading {
    .progress-bar {
      @apply flex flex-col;
    }
    .img-preview, .filetype-icon {
      @apply hidden;
    }
  }

  &:not(.uploading) {
    .upload-loader {
      @apply hidden;
    }
  }

  &.error {
    .status-text {
      @apply text-danger;
    }
  }
}