.oq-radios {
  &.appearance--radio {
    @apply flex flex-col gap-1;
    .oq-radio {
      label {
        @apply flex items-center space-x-2 text-md;
        span {
          @apply opacity-60;
        }
        &:has(> input[type="radio"]:checked) {
          span {
            @apply opacity-100;
          }
        }
      }
      input[type="radio"] {
        @apply h-5 w-5 border-gray-300 text-brand-600 focus:ring-brand-600;
        &:checked {
          @apply border-brand-600;
        }
      }
    }
  }

  &.appearance--button {
    @apply flex flex-wrap gap-2 bg-gray-200 rounded-lg p-1 border w-fit;
    .oq-radio {
      @apply h-10 block;
      label {
        height: inherit;
        @apply flex justify-center items-center px-6 py-3 rounded-md text-base font-normal text-gray-600 cursor-pointer;
        input[type="radio"] {
          @apply absolute inset-0 hidden -z-10;
        }
        &:hover {
          @apply bg-gray-50;
        }
        &:has(> input[type="radio"]:checked) {
          @apply bg-white text-black drop-shadow;
          &:hover {
          }
        }
      }
    }
  }
}

.oq-form-group.is-invalid {
  .oq-radios {
    .oq-radio {
      input[type="radio"] {
        @apply border-danger;
      }
    }
  }
}