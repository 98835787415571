@import 'mapbox-gl/dist/mapbox-gl.css';

.map {
  .map__autocomplete-input {
    @apply mb-2;
  }
  .map__autocomplete-list {
    li {
      @apply px-2.5 py-1.5 text-sm cursor-pointer hover:bg-extra-light;
    }
  }
  .map__map {
    @apply border border-slate-200 rounded-lg h-72 relative;
  }
}