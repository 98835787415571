.autocomplete {
  &--disabled {
    @apply pointer-events-none opacity-50;
  }
  .oq-dropdown {
    .dropdown__menu {
      @apply pt-0 overflow-hidden top-14 w-full;
      .autocomplete-list {
        @apply overflow-y-scroll mt-2 max-h-[10.9rem] divide-grey;
        .tempMsg {
          @apply pl-4 pt-4 text-grey font-medium text-base;
        }
        .autocompleteList__item {
          @apply px-2.5 py-1.5 cursor-pointer hover:bg-purple-100;
          &--disabled {
            @apply text-gray-400 pointer-events-none;
          }
          &--disabled-selected {
            @apply text-gray-400 pointer-events-none after:content-['_(Déjà_ajouté)'];
          }
          span {
            @apply text-grey font-medium text-base;
          }
          &--disabled-selected,
          &--disabled {
            span {
              @apply text-gray-400;
            }
          }
        }
      }
    }
  }
}
