.drawer {
  @apply relative z-10;

  .drawer-box {
    @apply duration-700 transition transform ease-in-out;
    @apply -translate-x-full;

  }

  &:not(.open) {
    .overlay {
      @apply hidden;
    }
  }

  &.open {
    .drawer-box {
      @apply translate-x-0;
    }
  }

  .overlay {
    @apply fixed inset-0 transition-opacity;
    background: #000929;
    opacity: 0.25;
  }

  .drawer-container {
    @apply duration-700 transition transform pointer-events-none fixed inset-y-0 flex max-w-full;

    .close-btn {
      @apply shrink-0;
    }
  }

  &.align--right {
    .drawer-container {
      @apply right-0;
      .close-btn {
        @apply ml-4;
      }
    }
  }

  &.align--left {
    .drawer-container {
      @apply left-0;
      .close-btn {
        @apply mr-4;
      }
    }
  }
}
