@import "stylesheets/colors";

$types: ("pill", "modern", "modern_solid", "notification");
$sizes: ("xs", "sm", "md", "lg", "base");

@mixin setBadgeStyle($type, $size, $color) {
  @if $size == "xs" {
    @apply text-2xs px-1.5 py-0.5;
    & > .badge__icon {
      @apply mr-0.5 h-3 w-3;
    }
    &.icon-only {
      @apply p-0.5;
    }
  }

  @if $size == "sm" {
    @apply text-xs h-5.5 px-2 py-0.5;
    & > .badge__icon {
      @apply h-3 w-3;
    }

    &.icon-only {
      @apply px-1;
    }
  }

  @if $size == "md" {
    @apply text-sm h-6 px-2.5 py-0.5;
    & > .badge__icon {
      @apply h-3 w-3;
    }

    &.icon-only {
      @apply p-0.5;
    }
  }

  @if $size == "lg" {
    @apply text-sm h-7 px-3 py-1;
    & > .badge__icon {
      @apply h-3 w-3;
    }

    &.icon-only {
      @apply p-1;
    }
  }

  @if $type == "pill" {
    background-color: theme("colors.#{$color}.50");
    border-color: theme("colors.#{$color}.200");
    color: theme("colors.#{$color}.700");
    & > .badge__icon {
      color: theme("colors.#{$color}.500");
    }
    &.badge--icon-emphasis {
      .badge__icon {
        border-color: theme("colors.#{$color}.200");
      }
    }
  }

  @if $type == "modern" {
    @apply bg-white border border-gray-300;

    @if $size == "sm" {
      @apply rounded-sm px-1.5;
    }
    @if $size == "md" {
      @apply rounded-sm px-2;
    }
    @if $size == "lg" {
      @apply rounded-md px-2.5;
    }


    & > .badge__icon {
      color: theme("colors.#{$color}.500");
    }
  }

  @if $type == "notification" {
    background: theme("colors.#{$color}.500");
    border-color: theme("colors.#{$color}.500");
    @apply text-white rounded-full p-0 flex items-center justify-center;
    @if $size == "xs" {
      @apply h-4 w-4 px-1.5 py-1.5;
    }
    @if $size == "sm" {
      @apply h-5.5 w-5.5 px-1.5 py-1.5;
    }
    @if $size == "md" {
      @apply h-6 w-6 px-2 py-2;
    }
    @if $size == "lg" {
      @apply h-7 w-7 px-2.5 py-2.5;
    }
  }

  @if $type == "modern_solid" {
    @if $size == "sm" {
      @apply rounded-sm px-1.5;
    }
    @if $size == "md" {
      @apply rounded-sm px-2;
    }
    @if $size == "lg" {
      @apply rounded-md px-2.5;
    }

    background-color: theme("colors.#{$color}.50");
    border-color: theme("colors.#{$color}.200");
    color: theme("colors.#{$color}.700");
    & > .badge__icon {
      color: theme("colors.#{$color}.500");
    }
    &.badge--icon-emphasis {
      .badge__icon {
        border-color: theme("colors.#{$color}.200");
      }
    }
  }
}

.oq-badge {
  @apply flex items-center rounded-full px-2 py-1 leading-normal font-medium select-none whitespace-nowrap w-min border truncate text-ellipsis;

  &.with-nested, &.with-avatar {
    @apply pl-0.5 #{!important};
    .oq-badge, .oq-avatar {
      @apply mr-1;
    }
  }

  &.icon-only {
    @apply p-1;
    .badge__icon {
      @apply m-0 #{!important};
    }
    &.icon-only {
      @apply p-0.5;
    }
  }

  .badge__icon {
    @apply h-4 w-4 shrink-0 stroke-2 rounded-full;
    &.icon__trailing {
      @apply ml-1;
    }
    &:not(.icon__trailing) {
      @apply mr-1;
    }
  }

  &.badge--icon-emphasis {
    .badge__icon {
      @apply bg-white border;
    }
  }

  @each $type in $types {
    &.badge--type-#{$type} {
      @each $size in $sizes {
        &.badge--size-#{$size} {
          @each $color in $colors {
            &.badge--color-#{$color} {
              @include setBadgeStyle(#{$type}, #{$size}, #{$color});
            }
          }
        }
      }
    }
  }
}
