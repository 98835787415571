.oq-select {
  @apply max-w-lg;
  &.disabled {
    .dropdown__toggler:not(.custom-toggler) {
      @apply bg-gray-50 pointer-events-none text-tertiary;
    }
  }

  .oq-select__prompt {
    @apply text-base flex items-center flex-wrap px-3;
    &.no-value {
      @apply text-light;
    }
    .display-option {
      @apply flex items-center;
    }
  }

  .oq-dropdown {
    @apply w-full;

    &.open {
      .dropdown__toggler:not(.custom-toggler) {
        @apply border-primary-300 ring-4 ring-brand-500/[.24] #{!important};
      }
    }
  }

  .dropdown__toggler:not(.custom-toggler) {
    @apply rounded-md bg-white font-normal text-black min-h-10 border border-gray-300;
    filter: drop-shadow(0px 1px 2px #1018280D);
  }

  &.with-icon {
    .dropdown__toggler:not(.custom-toggler) {
      @apply pl-8;
    }
  }

  .dropdown__menu {
    @apply max-h-80;
  }

  &.multiple {
    .menu__item {
      &:before {
        @apply h-4 w-4 bg-purple-100 mr-2 rounded-md shrink-0;
        content: "";
      }

      &.selected {
        &:before {
          @apply bg-primary;
          content: url('assets/images/app/icons/check-white.svg');
        }
      }
    }

    .oq-select__prompt {
      @apply text-light text-base font-normal not-italic;
      &:has(.display-option) {
        @apply px-1 pt-1;
        .display-option {
          @apply text-black bg-primary-50 rounded-md border pl-2 mr-1 mb-1;
        }
      }
    }
  }

  &.is-invalid {
    .dropdown__toggler:not(.custom-toggler) {
      @apply border-danger;
    }
    .oq-input {
      @apply border-light #{!important};
    }
  }

  &.is-invalid {
    .dropdown__toggler:not(.custom-toggler) {
      @apply border-danger;
    }
    .oq-input {
      @apply border-light #{!important};
    }
  }
}
