@import 'stylesheets/colors';

.heading {
  @apply flex items-center;

  .heading__picto {
    @apply mr-4 h-6 w-6;
  }

  &.heading--h1 {
    @apply text-xl font-semibold text-blue-800;
    .heading__picto {
      @apply h-8 w-8 mr-4;
    }
  }

  &.heading--h2 {
    @apply text-lg font-semibold;
  }

  &.heading--h3 {
    @apply text-base font-medium text-light;
    .heading__picto {
      @apply h-5 w-5 mr-3;
    }
  }

  &.heading--h4 {
    @apply text-sm font-medium text-blue-800;
  }

  &.heading--subtitle {
    @apply text-sm text-light;
    .heading__picto {
      @apply mr-1 h-4 w-4;
    }
  }

  &.heading--color-warning {
    @apply text-warning;
  }

  &.heading--color-white {
    @apply text-white;
  }
}
