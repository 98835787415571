.select-input__wrapper {
  @apply w-full;
  .dropdown__toggler {
    @apply flex text-sm items-center border border-gray-300 rounded-2xl pl-5 pr-3 py-3 text-oqoro-blue cursor-pointer bg-white;
    div {
      @apply flex items-center flex-grow;
    }
    .caret {
      @apply text-light h-4 w-4 ml-2;
    }
    .user-icon {
      @apply absolute left-[20px] h-7 text-oqoro-purple;
    }
    .item__icon {
      @apply mr-4 text-light;
    }
  }
  &.select-input__with-icon {
    .dropdown__toggler {
      @apply pl-16;
    }
  }
  &.oqoro {
    .dropdown__toggler {
      @apply rounded-1.5xl text-blue-main border-grey-light text-base hover:border-gray-700;
    }
    &.field {
      @apply px-0;
    }
  }

  .dropdown__menu {
    max-height: 300px;
    @apply overflow-auto;

    .search__wrapper {
      @apply flex items-center space-x-2 text-light p-2 border-b border-slate-200 sticky top-0 bg-white;
      .search__icon {
        @apply w-6 h-6;
      }
      .search__input {
      }
    }

    .menu__item {
      @apply flex items-center px-4 py-2 cursor-pointer text-sm font-medium text-light;
      @apply hover:bg-primary hover:text-white;
      .item__icon {
        @apply mr-4;
      }
    }
  }
}
