@import 'components/MBA/button/component.scss';

.toggle-field {
  @apply flex flex-wrap items-center space-x-1;
  .toggle-button {
    &.active {
      .button {
        @extend .button--toggle-style-outline-active;
      }
    }
    &:not(.active) {
      .button {
        @extend .button--toggle-style-outline;
      }
    }
  }
}
