.mba-card {
  @apply relative flex flex-col bg-white rounded-2xl;

  &.bordered {
    @apply border border-slate-200;
  }

  .card__image {
    @apply rounded-t-2xl h-48;
  }

  .card__link {
    @apply absolute block h-full w-full rounded-2xl;
  }

  &.padded {
    .card__content {
      @apply p-5;
      height: -webkit-fill-available;
    }
  }
}
