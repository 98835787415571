.oq-popover {
  @apply relative;
  .popover__card {
    @apply z-10 absolute drop-shadow-lg bg-white border rounded-md w-fit mt-2 w-max max-w-3xl;
  }

  &.align--right {
    .popover__card {
      @apply right-0;
    }
  }
}