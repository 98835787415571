.oq-omnibar {
  .omnibar-results {
    @apply flex flex-col space-y-2 py-1;
    .result-group {
      @apply flex flex-col;
      .group__name {
        @apply p-2 font-bold text-light text-sm;
      }
      .group-results {
        .result {
          @apply flex items-center p-2;
          &:hover, &.selected {
            @apply bg-primary text-white;
            .result__icon {
              @apply text-white;
            }
          }
          .result__icon {
            @apply shrink-0 text-light w-4 h-4 mr-2;
          }
        }
      }
    }
  }
}