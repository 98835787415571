.mba-modal {
  &:not(.open) {
    @apply hidden;
  }

  @apply fixed z-10 inset-0 overflow-y-auto;

  &.empty {
    .modal__container {
      .modal__panel {
        @apply w-fit;
      }
    }
  }

  &.scrolling_up {
    @apply block opacity-0 invisible transition-all duration-500;

    .modal__container {
      @apply transition-all duration-500 justify-end px-0 pb-0;

      .modal__panel {
        @apply flex transition-transform translate-y-full duration-500 flex flex-col w-full fixed bottom-0;
        .panel__content {
          @apply self-center;
        }
      }
    }

    .modal__panel {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.open {
      @apply opacity-100 visible;

      .modal__container {
        .modal__panel {
          @apply translate-y-[0%];
        }
      }
    }
  }

  .progress {
    @apply h-2 w-20 flex justify-self-center place-self-center;
    position: relative;
    border: 1px solid #e4e4e4;
    background-color: #e4e4e4;
    border-radius: 15px;

    .progress-color {
      position: absolute;
      background-color: #929bb4;
      width: 10%;
      height: 100%;
      border-radius: 15px;
      animation: progres 4s linear infinite;
    }
  }

  @keyframes progres {
    0% {
      width: 0%;
    }
    25% {
      width: 50%;
    }
    50% {
      width: 75%;
    }
    75% {
      width: 85%;
    }
    100% {
      width: 100%;
    }
  }

  .modal__container {
    @apply fixed flex flex-col items-center justify-center w-screen py-4 px-4 md:pb-20 text-center sm:block;
    height: 100dvh;

    .modal__overlay {
      @apply fixed inset-0 transition-opacity;
      background: rgba(53, 73, 96, 0.92);
    }

    .modal__panel {
      @apply relative inline-block align-bottom bg-white rounded-3xl text-left overflow-auto shadow-xl transition-all sm:my-8 sm:align-middle w-full;

      @media (max-width: 639px) {
        position: absolute;
        bottom: 0;
        max-height: 75%;
        border-radius: 30px 30px 0px 0px;
        transform: translateY(100%);

        &.open {
          animation: slide-in 600ms forwards;
          @keyframes slide-in {
            to {
              transform: translateY(0);
            }
          }
        }
      }

      .modal__close-btn {
        @apply cursor-pointer absolute right-6 top-6 text-gray-700 w-8 h-8;
      }

      .modal__timer {
        @apply h-2 w-20 mb-8 flex justify-self-center self-center mx-auto place-self-center relative border rounded-full;
        background-color: #e4e4e4;
        .modal__timer-progress {
          @apply absolute h-full rounded-full;
          background-color: #929bb4;
        }
      }
    }
  }

  &.padded {
    .panel__content {
      @apply p-8;
    }
  }
}
