@import 'stylesheets/colors';

.scroller {
  @apply relative overflow-hidden;
  .scroller__container {
    @apply flex space-x-4 overflow-x-auto p-4;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      @apply hidden;
    }
  }

  .arrow {
    @apply p-4;
  }

  &.no-scroll {
    .arrow {
      @apply hidden;
    }
  }

  &.scroller--arrows-inside {
    .arrow {
      @apply absolute shadow-lg top-1/2 transform -translate-y-1/2 z-10;
      @apply -sm:hidden;
      &.disabled {
        @apply hidden;
      }

      &.next {
        @apply right-4;
      }

      &.prev {
        @apply left-4;
      }
    }
  }

  &.scroller--arrows-below {
    .arrow-container {
      @apply self-center justify-center flex items-center space-x-4;
      .arrow {
        @apply p-6 rounded-full bg-white border border-2 border-blue;
        &:not(:hover) {
          @apply text-blue-main;
        }

        &.disabled {
          @apply text-light;
          border-color: $light-color;
          pointer-events: none;
        }

        &.next {
          @apply right-4;
        }

        &.prev {
          @apply left-4;
        }
      }
    }
  }
}
