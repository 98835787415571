.oq-title-bar {
  @apply flex items-center w-full;

  .title-bar__title-container {
    @apply flex mr-8 space-x-4;
    .title-bar__title {
      @apply text-lg font-semibold text-gray-900;
    }
    .title-bar__subtitle {
      @apply text-md font-medium;
    }
  }
  .title-bar__actions {
    @apply flex items-center space-x-2;
  }
}
