.tab__wrapper {
  @apply flex flex-col;


  &.orientation--horizontal {
    .tab__container {
      @apply flex items-stretch border-b snap-x overflow-x-auto gap-6 pb-2;
      .tab {
        @apply py-2 snap-start border-b-2 -my-2;
      }
      .tab__items {
        @apply flex items-center gap-2 ml-auto;
      }
    }
  }

  &.orientation--vertical {
    .group__container {
      @apply flex flex-col;
      .tab__group:not(:last-child) {
        @apply border-b;
      }
    }
    .tab__container {
      @apply flex flex-col;
      .tab {
        @apply border-l-3 w-full px-4 py-1 h-10;
        &:hover {
          @apply bg-purple-50;
        }
      }
    }
  }

  .tab {
    @apply font-semibold flex items-center space-x-2 border-transparent shrink-0 cursor-pointer;

    &:not(.active) {
      @apply text-gray-500;
    }

    &.active {
      @apply text-brand border-brand;
    }

    &.disabled {
      @apply cursor-default;
    }

    .tab__label {
      @apply truncate grow;
    }

    .tab__icon {
      @apply h-5 w-5 mr-2 shrink-0;
    }
    &.has-error {
      &:after {
        @apply bg-danger h-4 w-4 rounded-full text-white font-bold ml-2 flex items-center justify-center shrink-0;
        content: "!";
      }
    }
  }
}