$colors: ("primary", "success", "warning", "danger", "gray");

@mixin setItemStyle($color) {
  color: theme("colors.#{$color}.500");
  &:hover {
    background-color: theme("colors.#{$color}.500");
    color: white;
  }
}

.oq-dropdown {
  @apply relative inline-block max-w-lg;

  .dropdown__toggler {
    @apply select-none;
  }

  &.size--md {
    .dropdown__menu {
      @apply w-56;
    }
  }

  &.size--lg {
    .dropdown__menu {
      @apply w-192;
    }
  }

  &:not(.open) {
    & > .dropdown__menu {
      @apply hidden;
    }
  }

  &.open {
    & > .dropdown__menu {
      @apply flex;
    }
  }

  .dropdown__menu {
    @apply z-2 flex-col transition transform fixed bg-white border-1 p-2 rounded-lg drop-shadow-lg;
    top: calc(100% + 4px);

    .menu__section {
      @apply flex flex-col;
      .menu__section--label {
        @apply p-2 font-medium text-black;
      }
      &:not(:last-of-type) {
        @apply border-b pb-2;
      }
      &:not(:first-of-type) {
        @apply pt-2;
      }
    }

    .menu__item {
      @apply text-base font-medium flex items-start p-2 w-full text-left select-none rounded-md cursor-pointer;
      &:hover {
        @apply bg-primary text-white;
      }

      @each $color in $colors {
        &.color--#{$color} {
          @include setItemStyle(#{$color});
        }
      }

      .item__icon {
        @apply h-4 w-4 mr-2 mt-0.5 stroke-1.5;
      }

      &.disabled {
        @apply text-tertiary/40 cursor-not-allowed;
        &:hover {
          @apply bg-white;
        }
      }
    }
  }

}
