@import "stylesheets/colors";

.mba-alert {
  @apply bg-white m-8 mx-auto p-4 flex flex-row rounded-xl self-center border;

  .mba-alert__head__icon {
    @apply flex-shrink-0 m-4 ;
  }

  .mba-alert__content {
    @apply flex flex-col flex-grow justify-center ;
  }

  .mba-alert__content__title {
    @apply text-lg font-semibold mb-2;
  }

  .mba-alert__content__text {
    @apply text-md mb-0;
  }

  .mba-alert__content__cta {
    @apply self-end my-4;
  }
}

/*** colors ***/
@each $color-name, $color-value in $mba-colors {
  .mba-alert-#{"" + $color-name} {
    border-color: $color-value;
  }

  .mba-alert__head__icon-#{"" + $color-name} {
    color: $color-value;
  }

  .mba-alert__content__title-#{"" + $color-name} {
    color: $color-value;
  }
}
