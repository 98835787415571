.oq-radio {
  .oq-radio__label {
    @apply transition-all;
  }

  &.variant--default {
    input[type="radio"] {
      @apply mr-2;
    }
  }

  &.variant--button {
    @apply flex;
    .oq-radio__label {
      @apply grow cursor-pointer border rounded-xl flex justify-center items-center px-3 py-2 bg-white;
      &:hover {
        @apply bg-primary-50;
      }
    }

    input[type="radio"] {
      @apply hidden;
      &:checked + .oq-radio__label {
        @apply bg-primary text-white;
      }
      &:disabled + .oq-radio__label {
        @apply pointer-events-none border-white text-deepblue-200;
      }
    }
  }
}
