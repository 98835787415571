.MBA-tab__wrapper {
  @apply flex flex-row items-center space-x-8;

  .MBA-tab__tab {
    @apply cursor-pointer;
    @apply text-sm font-semibold flex-shrink-0 border-b-4 border-transparent pb-2 text-slate;
  &.active {
      @apply text-primary border-primary;
    }
  }
}

.MBA-tab__panel {
  @apply flex flex-col;
  &:not(.open) {
    display: none;
  }
}
