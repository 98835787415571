.oqoro-input-checkbox-button {
  .checkbox-button {
    @apply rounded-xl text-blue-main text-md font-medium px-4 py-2 border border-grey text-center cursor-pointer;

    &.checked {
      @apply font-extrabold text-white bg-saumon-main border-transparent;

    }
  }
}

.oqoro-input-checkbox-button-collection {
  @apply flex flex-wrap gap-2;
}