@import "../../_colors";

.search-container {
  @apply flex flex-grow overflow-hidden relative z-0;

  #search-list {
    @apply overflow-y-auto flex flex-col w-full lg:w-1/2 flex-shrink-0 z-10 bg-white translate-x-0;
    @apply transition-transform duration-700;
  }

  #search-filters {
  }

  .map-container {
    @apply transition-transform duration-700;
  }

  #map-toggler {
    @apply transition-all duration-700 absolute top-4 border border-grey text-blue-main bg-white rounded-full stroke-1.5;
    width: 50px;
    height: 50px;
    left: (calc(25% + 16px));
  }

  .map-container {
    @apply hidden lg:flex flex-col overflow-hidden;
    @apply absolute inset-0 translate-x-1/4;

    .toggle-refresh-results {
      @apply absolute left-1/2 bottom-8 bg-white rounded-full border border-grey py-2.5 px-6 -translate-x-1/2 text-sm shadow-lg cursor-pointer font-extrabold z-30;
      &.active {
        @apply bg-purple-main text-white;
      }
    }

    .zoom-buttons {
      @apply w-10 flex justify-center transition-all duration-700 font-extrabold text-xl items-center flex-col text-oqoro-blue absolute left-[70%] -translate-x-1/2 bottom-8 bg-white rounded-lg border-[1.5px] border-grey shadow-lg z-30;
    }

    .map-loader {
      @apply hidden absolute left-1/2 bottom-8 bg-white rounded-lg p-2 -translate-x-1/2 text-xs shadow-lg cursor-pointer font-medium z-30;
      $initColor: rgba(0, 0, 0, 0.1);
      $colorEnd: $color-saumon;
      .preloader {
        @apply relative h-8 mx-4 w-16;
        span {
          @apply absolute block bottom-3;
          width: 9px;
          height: 5px;
          border-radius: 5px;
          background: $initColor;
          animation: preloader 2s infinite ease-in-out;
          @for $i from 1 through 5 {
            &:nth-child(#{$i + 1}) {
              left: 11 * $i + px;
              animation-delay: 200 * $i + ms;
            }
          }
        }
      }

      @keyframes preloader {
        0% {
          height: 5px;
          transform: translateY(0);
          background: $initColor;
        }
        25% {
          height: 30px;
          transform: translateY(15px);
          background: $colorEnd;
        }
        50%,
        100% {
          height: 5px;
          transform: translateY(0);
          background: $initColor;
        }
      }
    }

    .map-marker {
      @apply transition-colors w-min z-20 bg-white cursor-pointer border border-grey rounded-full text-blue-main font-extrabold px-4 py-2 text-lg shadow-lg;
      &.active {
        @apply bg-saumon-main text-white;
      }
      &:hover,
      &.active {
      }
    }
  }

  .filter-container {
    @apply translate-x-0 z-30;
  }

  &.full-map {
    #search-list {
      @apply -translate-x-full;
    }

    .map-container {
      @apply translate-x-0;
    }
    .zoom-buttons {
      left: 96%;
    }

    .filter-container {
      @apply absolute top-8 w-full;
      @apply translate-x-full;
    }

    .search-results-title {
      @apply hidden;
    }

    #map-toggler {
      @apply left-4 text-white bg-saumon-main;
    }
  }

  .loading-placeholder {
    display: none;
  }

  &.loading {
    .loading-placeholder {
      display: block;
    }
    .map-loader {
      @apply block;
    }
    .toggle-refresh-results {
      @apply hidden;
    }
  }
}
