.oq-sidebar {
  @apply flex flex-col w-full shrink-0 border-r z-1 overflow-hidden;

  &.open {
    @apply sm:w-64;
  }

  &:not(.open) {
    @apply w-20;
    .sidebar__menu {
      .menu__item {
        &:hover {
          .item__label {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }

        .item__label {
          //@apply sm:hidden;
          //@apply absolute text-white bg-gray-900 px-3 py-2 rounded-lg;
          //left: calc(100% - 4px);
          //opacity: 0;
          //transform: translate3d(-10px, 0, 0);
          //transition: all .15s ease-in-out;
          //
          //&::after {
          //  content: "";
          //  @apply absolute top-1/2 right-full;
          //  margin-top: -5px;
          //  border-width: 5px;
          //  border-style: solid;
          //  border-color: transparent black transparent transparent;
          //}
        }
      }
    }
  }

  .sidebar__logo {
    @apply py-8 lg:p-4;
  }

  .sidebar__menus {
    @apply absolute w-full bg-white flex-col inset-0 top-16 pb-16 border-t space-y-8;
    @apply sm:top-16 sm:pb-0;
    @apply md:relative md:flex-grow md:top-0 md:border-t-0 px-0;
    @apply flex flex-col;
  }

  .sidebar__menu {
    @apply flex-col p-4 space-y-2;
  }

  .menu__item {
    @apply relative h-12 w-12 flex flex-row text-sm text-gray-500 p-2 select-none text-lg rounded-md items-center text-ellipsis whitespace-nowrap;

    .item__icon {
      @apply rounded-md shrink-0 flex justify-center items-center w-8 h-8;
      svg {
        @apply h-6 w-6 stroke-2;
      }
    }

    .item__label {
      @apply font-medium text-sm ml-4;
    }

    &:hover,
    &.menu__item--active {
      @apply bg-gray-200;
      svg {
        @apply text-brand-600;
      }
    }

    &.menu__item--active {
      @apply bg-gray-200 rounded-md;
      .item__icon {
        @apply md:bg-gray-200 md:rounded-md;
        svg {
          @apply text-brand-600;
        }
      }
    }
  }

  #burger {
    @apply block h-6 w-6 relative cursor-pointer transform rotate-0 transition-all duration-500 ease-in-out;
    @apply md:hidden;
    span {
      @apply block absolute h-1 w-full left-0 rounded-full transform rotate-0 transition-all duration-200 ease-in-out bg-dark;
      &:nth-child(1) {
        @apply top-0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        @apply top-2.5;
      }

      &:nth-child(4) {
        @apply top-5;
      }
    }

    &.open {
      span {
        @apply h-[0.15rem];
        &:nth-child(1) {
          @apply top-2.5 w-0 left-1/2;
        }

        &:nth-child(2) {
          @apply transform rotate-45;
        }

        &:nth-child(3) {
          @apply transform -rotate-45;
        }

        &:nth-child(4) {
          @apply top-0.5 w-0 left-1/2;
        }
      }
    }
  }

  &.icon-only {
    //@apply sm:w-20;
    .sidebar__menu {
      @apply space-y-6;
      .menu__item {
        @apply sm:justify-center;
        .item__icon {
          @apply h-10 w-10 p-2 sm:mr-0;
          svg {
            @apply h-6 w-6;
          }
        }

        .item__label {
          @apply sm:hidden;
        }
      }
    }
  }

  &:not(.icon-only) {
    .sidebar__menu {
      .menu__item {
        @apply sm:justify-center flex flex-col;
        .item__icon {
          @apply pb-0;
        }
        .item__label {
          @apply sm:block text-2xs ml-0 leading-3;
        }
      }
    }
  }
}
