.mba-modal {
  &.modal--fullscreen {
    height: 100%;
    animation: 400ms modal-open-slide;
    &-close {
      display: block;
      animation: 400ms modal-close-slide forwards;
    }
    .modal__container {
      height: 100%;
      @apply p-0;
    }
    .modal__overlay {
      display: none;
    }
    .modal__trick {
      @apply sm:h-full;
    }
    .modal__panel {
      @apply m-0 h-screen w-screen max-w-none rounded-none;
      height: 100%;
      max-height: 100%;
    }
    .panel__content {
      @apply flex flex-col flex-grow overflow-y-auto;
      height: inherit;
      opacity: 0;
      animation: 400ms 400ms content-fadein forwards;
      padding: 0 !important;
    }
  }
}

@keyframes modal-open-slide {
  from {
    transform: translateY(100vh);
    opacity: 0;
  }
  to {
    transform: translateY(0vh);
    opacity: 1;
  }
}
@keyframes content-fadein {
  to {
    opacity: 1;
  }
}
@keyframes modal-close-slide {
  from {
    transform: translateY(0vh);
    opacity: 1;
  }
  to {
    transform: translateY(100vh);
    opacity: 0;
    display: none;
  }
}
