.helpbot {
  @apply flex sm:space-x-8 items-start max-w-full;
  .helpbot__avatar {
    @apply hidden sm:block;
    @apply relative h-20 w-20 flex-shrink-0;
    .avatar__image {
      @apply rounded-full flex items-center justify-center bg-black
    }
    .avatar__notification {
      @apply h-5 w-5 bottom-0.5 right-0.5 absolute rounded-full bg-green-300 border-2 border-white;
    }
  }
  .helpbot__content {
    @apply flex flex-col max-w-full text-light pt-2 text-sm;
    .avatar__name {
      @apply hidden sm:block text-blue-main;
    }
  }
}
