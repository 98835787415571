.file-preview {
  @apply text-blue-main p-2 flex items-center justify-between w-full rounded-xl border border-grey text-base font-medium;

  .doc-icon {
    @apply text-purple-main;
  }
  .main-container {
    @apply flex flex-col md:w-full md:flex-row md:items-center md:justify-between overflow-hidden;
    .file-name {
      @apply whitespace-nowrap md:ml-4;
    }
    .confirmation {
      @apply flex items-center space-x-2 md:flex-row-reverse text-sm;
      .check-round {
        @apply text-success w-6 h-6 md:mr-8;
      }
    }
  }
  .delete-btn {
    @apply text-light items-center ml-1 cursor-pointer;
  }
}
