form {
  margin-block-end: 0 !important;
}

[type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
  &.oq-input {
    @apply px-3.5 py-2.5 rounded-md bg-white font-normal text-md text-gray-900 min-h-10 border-gray-300 w-full;
    filter: drop-shadow(0px 1px 2px #1018280D);

    &:not(textarea) {
      @apply h-10;
    }

    &:disabled, &:read-only {
      @apply bg-gray-50 pointer-events-none text-tertiary;
    }

    &.size--sm {
      @apply min-h-8 h-8;
    }

    &::placeholder {
      @apply text-light text-base font-normal not-italic;
    }

    &:placeholder-shown {
      @apply text-ellipsis;
    }

    &.with-icon {
      @apply pl-10.5;
    }

    &.appearance--ghost {
      @apply border-0 ring-0 bg-transparent;
    }

    &:focus {
      .input__icon {
        @apply opacity-100;
      }
    }

    &:not(.appearance--ghost) {
      &:focus, &:active {
        @apply border-primary-300 ring-4 ring-brand-500/[.24] #{!important};
      }
    }
  }
}

.input__icon {
  top: calc(50% - 10px);
  @apply text-gray-500 flex items-center stroke-2 absolute left-3.5 h-5 w-5;
  svg {
    @apply h-5 w-5;
  }
}

.oq-label {
  @apply text-sm font-medium text-gray-700;
}

.oq-text-area {
  resize: none;
  box-sizing: border-box;
}

.oq-form {
  @apply flex flex-col w-full;

  &.orientation--vertical {
    /*** labels ***/
    .oq-form-group {
      @apply flex-col;
      .oq-form-label {
        @apply mb-2;
      }
    }
  }

  &.orientation--horizontal {
    .oq-form-group:not(.nested) {
      @apply flex-row -sm:flex-wrap flex-nowrap mb-2 items-start gap-4 -sm:gap-2;
      /*** labels ***/
      .oq-form-label {
        @apply w-48 shrink-0;
      }
    }
  }

  &.orientation--inline {
    @apply flex flex-row flex-nowrap gap-2;
    & > .oq-form-group {
      @apply flex-col grow;
      /*** labels ***/
      & > .oq-form-label {
        @apply mb-1;
      }
    }
  }

  .oq-form-group {
    &.is-invalid {
      .oq-form-label {
      }

      [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
        &.oq-input {
          @apply border-danger;
        }
      }
    }
  }

  [type="submit"] {
    @apply w-min;
  }
}

/*** labels ***/
.oq-form-label {
  @apply text-gray-700 text-sm font-medium mt-1 leading-tight;
  text-wrap: balance;
}

/*** form groups ***/
.oq-form-group {
  @apply flex flex-col mb-2;
  &.nested {
    &:not(:first-of-type) {
      @apply border-t mt-4;
    }

    & > .oq-form-label {
      @apply block py-2 my-2 text-primary font-semibold relative;

    }
  }
}

.oq-input-group {
  @apply flex flex-col w-full;
}

/*** hint ***/
.form-hint {
  @apply flex gap-2 text-sm text-blue-600 bg-blue-50 px-3 py-2 rounded-lg w-fit;
}

.oq-form-group {
  .form-hint {
    @apply mt-2;
  }
}

.oq-form .nested-form {
  &.orientation--vertical {
    /*** labels ***/
    .oq-form-group {
      @apply flex-col space-x-0;
      .oq-form-label {
        @apply mb-2;
      }
    }
  }

  &.orientation--horizontal {
    .oq-form-group:not(.nested) {
      @apply flex-row flex-nowrap mb-2 items-start space-x-4;
      /*** labels ***/
      & > .oq-form-label {
        @apply w-48 shrink-0;
      }
    }
  }
}

/*** NO SPACING ***/
.oq-form.no-spacing {
  .oq-form-group {
    @apply mb-0;
  }
}

/*** errors ***/

.field-error, .form-error {
  @apply text-sm font-medium text-danger mt-1;
}
