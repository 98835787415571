.oq-read-more {
  @apply flex flex-col;
  .content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--read-more-line-clamp, 2);
    }
  .toggler {
    @apply cursor-pointer select-none underline mt-2;
  }
}