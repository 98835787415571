.oq-btn-group {
  @apply flex items-center space-x-2;
  &.btn-group--collapsed {
    @apply space-x-0;
    & > *:not(:first-child), & > *:not(:first-child) .oq-btn {
      @apply rounded-l-none #{!important};
    }
    & > *:not(:last-child), & > *:not(:last-child) .oq-btn {
      @apply rounded-r-none #{!important};
    }
  }
}