.oq-text-area {
  @apply relative z-0 w-full max-w-3xl;

  & > textarea {
    @apply max-w-3xl;
  }

  &.with-editor {
    @apply flex border border-light rounded-xl bg-white;
    max-width: unset;

    textarea {
      @apply hidden;
    }
  }

  &.with-icon {
    .input__icon {
      @apply left-3 top-3 items-start;
    }

    .oq-input {
      @apply pl-10;
    }
  }

  .toolbar {
    @apply flex items-center p-2 border-b;
    .toolbar__actions {
      @apply flex items-center gap-2 grow;
      .action {
        @apply h-5 w-5 stroke-2 bg-gray-50 p-0.5 rounded opacity-50;
        &.ql-active {
          @apply opacity-100;
          svg {
            @apply stroke-3;
          }
        }
      }
    }
  }

  &.disabled {
    .toolbar__actions {
      @apply hidden;
    }
  }

  .editor__summary {
    @apply w-48 border-r shrink-0 py-4;
    li {
      @apply cursor-pointer px-4 py-1 text-sm hover:text-primary;
    }
  }

  .ql-container {
    max-width: unset;
  }

  .ql-editor {
    @apply bg-white p-4 max-h-96 overflow-y-auto m-2 rounded border border-light whitespace-pre-wrap;
    h1 {
      @apply text-xl font-semibold;
    }

    ul {
      @apply list-inside list-disc;
    }

    ol {
      @apply list-inside list-decimal;
    }

    li[data-list=bullet] {
      @apply list-inside list-disc;
    }

    em {
      @apply italic;
      color: inherit;
      font-weight: inherit;
    }
  }

  .ql-disabled {
    .ql-editor {
      @apply bg-gray-50 opacity-80 border-transparent;
    }
  }

  .ql-clipboard {
    @apply hidden;
  }
}

@for $i from 1 through 8 {
  .ql-indent-#{$i} {
    padding-left: calc($i * 16px);
  }
}
