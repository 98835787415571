@import "stylesheets/colors";

.breadcrumb {
  @apply text-sm text-light;

  .breadcrumb__item {
    &.breadcrumb__item--link {
      @apply text-primary underline;
    }
  }
}

// Style for Breadcrumb on rails

ul.breadcrumb , ol.breadcrumb{
  @apply flex flex-wrap items-center text-xs;
  li {
    @apply px-2 text-blue-main font-extrabold;
    &:not(:last-child) {
      @apply pr-2;
    }
    a {
      @apply font-normal text-light hover:underline;
      color: colors("text-base");
    }
  }
}
