.oq-toggle {
  .toggle__button {
    @apply bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-600 focus:ring-offset-2;
    .toggle__tit {
      @apply translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out;
    }
  }

  &.enabled {
    .toggle__button {
      @apply bg-brand-600;
      .toggle__tit {
        @apply translate-x-5;
      }
    }
  }
}