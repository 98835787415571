.oq-notification {
  @apply transition transform duration-1000 absolute right-8 bottom-8;
  @apply z-20 flex w-full max-w-xs p-3 mb-4 bg-white rounded-lg shadow border-2;

  .title {
    @apply text-md font-medium text-black;
  }

  .text {
    @apply text-sm;
  }

  .notification__icon {
    @apply stroke-2 w-5 h-5 mr-2 shrink-0;
  }

  &.type--success {
    @apply border-success;
    .notification__icon {
      @apply text-success;
    }
  }

  &.type--danger {
    @apply border-danger;
    .notification__icon {
      @apply text-danger;
    }
  }

  &.type--info {
    @apply border-primary;
    .notification__icon {
      @apply text-primary;
    }
  }
}