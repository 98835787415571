.oq-datatable {
  @apply relative flex flex-col bg-white rounded-xl border overflow-hidden;
  filter: drop-shadow(0px 1px 2px  #1018280F);
  filter: drop-shadow(0px 1px 3px  #1018281A);
  .data__table {
    @apply text-sm border rounded-xl flex flex-col border-none overflow-auto relative;
    border-collapse: separate;
    border-spacing: 0;

    tbody, .data__body {
      @apply flex flex-col;
    }

    tr, .data__row {
      @apply flex flex-nowrap relative min-w-full w-max border-b;
    }

    .data__cell, .header__column {
      @apply table-cell shrink-0;
      text-wrap: nowrap;
    }

    .data__cell {
      @apply justify-center self-center;
    }

    .row-link {
      @apply block w-full h-full absolute inset-0;
    }

    .datatable__header {
      @apply text-xs text-gray-600 bg-brand-50 flex sticky top-0 z-2  border-b;

        .header__column {
          @apply inline-flex items-center shrink-0 text-left px-6 py-3 bg-brand-50;

          .column__label {
            @apply font-medium;
          }

          .sort__icon {
            @apply h-3 w-3 mr-1 shrink-0;
          }

          &.sortable {
            @apply cursor-pointer;
          }

          &.sorted {
            @apply text-brand;
          }
        }
    }

    .data__row {
      @apply flex relative even:bg-secondary;
      .data__cell {
        @apply px-6 py-3 align-top z-1;
        a {
          @apply pointer-events-auto;
        }
        a:hover {
          @apply underline;
        }
      }

      &:hover {
          @apply bg-primary-50;
      }
    }
  }

  &.display--card {
    table {
      @apply border-separate border-spacing-y-1;

      .datatable__header {
        .data__th {
          @apply uppercase text-light;
        }
      }
    }
    tr, .data__row {
      .data__cell {
        @apply bg-white border-y border-purple-200;
        &:first-of-type {
          @apply rounded-l-lg border-l;
        }
        &:last-of-type {
          @apply rounded-r-lg border-r;
        }
      }
      &:hover {
        .data__cell {
          @apply bg-purple-100;
        }
      }
    }
  }
}
