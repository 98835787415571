.oq-accordion {
  .accordion__toggler {
    @apply cursor-pointer;
  }

  .accordion__panel {
    transition: max-height 300ms ease-in-out;
    @apply overflow-hidden;

  }

  &:not(.open) {
    .accordion__toggler[data-action-type="close"][data-hide-action="true"] {
      @apply hidden;
    }
  }

  &.open {
    .accordion__panel.open {
      max-height: 10000px;
    }

    .accordion__toggler[data-action-type="open"][data-hide-action="true"] {
      @apply hidden;
    }
  }
}


.oq-accordion {
  details {
    summary {
      @apply text-base font-medium py-4;
      &::marker {
        @apply mr-4;
      }
    }

    &[open] {
      summary {
        &::marker {
        }
      }
    }
  }
}
